/* src/components/Map.css */
.marker {
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  cursor: pointer;
}

paddingbottom: 'env(safe-area-inset-bottom)', // iOS safe area
;
