body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* 실제 뷰포트 높이를 반영하는 CSS 변수 설정 */
:root {
  --vh: 100vh;
}

html,
body,
#root {
  /* height: calc(var(--vh, 1vh) * 100); */
  height: 100%;
  margin: 0;
  padding: 0;
}
