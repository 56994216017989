/* src/components/Login.css */
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* backgroundImage: url("/path/to/your/background-image.jpg"); 백그라운드 이미지 설정 */
  background-size: cover;
  background-position: center;
}

.logo {
  max-width: 300px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-top: 10%;
  margin-bottom: 20%;
}

.loginBox {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.title {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.inputWrapper {
  margin-bottom: 15px;
}

.label {
  display: block;
  margin-bottom: 5px;
  font-size: 1rem;
  color: #555;
}

.input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.button {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  color: #fff;
  background-color: #4caf50;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button:hover {
  background-color: #45a049;
}

.error {
  color: red;
  margin-bottom: 15px;
}
